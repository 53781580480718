body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: rgb(208, 220, 227);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content-container-main {
  height: 470px;
  width: 300px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 20px;
}

.qr-image {
  height: 300px;
  width: 300px;
  border-radius: 10px;
}

.heading {
  letter-spacing: 1px;
  padding: 10px;
  color: #090927;
}

.paratxt {
  color: gray;
  padding: 0px 10px 10px 10px;
}

@media screen and (max-width: 1440px) {}